export default () => {
  const { consumer } = useApi()
  const { $pluralize, $t } = useNuxtApp()
  const { showLoyaltyToastOnlyOnPDP } = useAppConfig().components.vf.loyalty
  const loyalty = useLoyaltyStore()
  const user = useUserStore()
  const loyaltyToken = ref({
    expiresAt: 0,
    memberToken: ''
  })
  const xUsidCookie = useCookie('x-usid')

  const updateLoyaltyToken = async () => {
    if (loyaltyToken.value.expiresAt > Date.now())
      return loyaltyToken.value

    try {
      const loyaltyTokenResponse = await consumer.$getLoyaltyToken({
        usid: xUsidCookie.value || uuid()
      }) || { memberToken: '', expiresIn: 0 }

      loyaltyToken.value = {
        memberToken: loyaltyTokenResponse.memberToken || '',
        expiresAt: Date.now() + (loyaltyTokenResponse.expiresIn || 0) * 1000
      }
    }
    catch (err) {
      console.error(err)
    }
  }

  const isLoyaltyToastNeeded = () => {
    if (user.details && !showLoyaltyToastOnlyOnPDP) return useSessionStorage('isLoyaltyToastAlreadyDisplayed', false)
  }

  const getLoyaltyWelcomePromoStatus = async () => {
    try {
      return await consumer.$getLoyaltyWelcomePromoStatus()
    }
    catch (err) {
      console.error(err)
    }
  }

  const getLoyaltyMessages = () => ({
    title: loyalty.rewardsAvailable?.length
      ? replaceAll(
        $pluralize(
          $t.loyaltyRewardsDetails.rewardsAvailable,
          loyalty.rewardsAvailable.length
        ),
        { amount: loyalty.rewardsAvailable.length }
      )
      : $t.loyaltyRewardsDetails.noRewards,
    text: loyalty.rewardsAvailable?.length
      ? $t.loyaltyRewardsDetails.rewardsHelpText
      : $t.loyaltyRewardsDetails.noRewardsHelpText,
    rewardsGreeting: user.details?.firstName
      ? replaceAll($t.loyaltyRewardsDetails.greetingWithName, { name: user.details.firstName })
      : $t.loyaltyRewardsDetails.greeting
  })

  return {
    getLoyaltyMessages,
    isLoyaltyToastNeeded,
    loyaltyToken,
    updateLoyaltyToken,
    getLoyaltyWelcomePromoStatus
  }
}
